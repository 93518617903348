<template>
  <form-inspection-list :is-confirmed-pag="false" />
</template>
<script>
import FormInspectionList from '@/components/FormComponents/InspectionList'

export default {
  components: {
    FormInspectionList,
  },
}
</script>
